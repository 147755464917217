.categoryMain {
    padding: 30px 0;
}

.categoryMainWrap {
    padding: 30px 6%;
}

.ListAllCategories {
    width: 100%;
    margin: 0 auto;
    display: grid;
    /* grid-gap: 12px;
    gap: 13px; */
    grid-template-columns: repeat(6, 1fr);
}

.ListAllCategories>div {
    /* background-color: #3d3d43; */
    border-radius: 10px;
}

.categorySelect {
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 48%);
    -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 48%);
    -moz-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 48%);
    background: #262628;
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #323232;
    outline: none;
    position: relative;
}

.selectBox {
    border-left: 2px solid #febd0e;
    padding-left: 10px;
    margin-top: 130px;
}
.ListAllCategories img {
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
    width: 100%;
    position: relative;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.ListAllCategories img {
    height: 340px !important;
}
  .ListAllCategories>div>div:first-child
  {
    height: 340px !important;
}

@media (max-width: 1920px) {
    .ListAllCategories img {
      height: 330px !important;
  }
    .ListAllCategories>div>div:first-child
    {
      height: 330px !important;
  }
  }
@media (max-width: 1700px) {
    .ListAllCategories img {
      height: 280px !important;
  }
    .ListAllCategories>div>div:first-child
    {
      height: 280px !important;
  }
  }
  @media (max-width: 1600px) {
    .ListAllCategories img {
      height: 260px !important;
  }
    .ListAllCategories>div>div:first-child
    {
      height: 260px !important;
  }
  }
  @media (max-width: 1500px) {
    .ListAllCategories img {
      height: 250px !important;
  }
    .ListAllCategories>div>div:first-child
    {
      height: 250px !important;
  }
  }
  @media (max-width: 1400px) {
    .ListAllCategories {
      gap: 10px;
      grid-template-columns: repeat(5, 1fr);
  }
  .ListAllCategories img {
    height: 270px !important;
  }
  .ListAllCategories>div>div:first-child
  {
    height: 270px !important;
  }
  }
  @media (max-width: 1300px) {
    .ListAllCategories img {
      height: 260px !important;
  }
    .ListAllCategories>div>div:first-child
    {
      height: 260px !important;
  }
  }
  @media (max-width: 1240px) {
    .ListAllCategories {
      grid-template-columns: repeat(4, 1fr);
  }
    .ListAllCategories img {
      height: 300px !important;
  }
    .ListAllCategories>div>div:first-child
    {
      height: 300px !important;
  }
  }
  @media (max-width: 1100px) {
    .ListAllCategories img {
      height: 260px !important;
  }
    .ListAllCategories>div>div:first-child
    {
      height: 260px !important;
  }
  }
  @media (max-width: 950px) {
    .ListAllCategories img {
      height: 240px !important;
  }
    .ListAllCategories>div>div:first-child
    >div>div:first-child
    {
      height: 240px !important;
  }
  }
  @media (max-width: 900px) {
    .ListAllCategories {
      grid-template-columns: repeat(3, 1fr);
  }
  .ListAllCategories img {
    height: 280px !important;
  }
  .ListAllCategories>div>div:first-child
  {
    height: 280px !important;
  }
  }
  @media (max-width: 800px) {
    .ListAllCategories img {
      height: 260px !important;
    }
    .ListAllCategories>div>div:first-child
    {
      height: 260px !important;
    }
    }
    @media (max-width: 750px) {
      .ListAllCategories img {
        height: 220px !important;
      }
      .ListAllCategories>div>div:first-child
      {
        height: 220px !important;
      }
    
      .ListAllCategories {
        width: 95%;
      }
    }
    @media (max-width: 640px) {
     
      .ListAllCategories {
        grid-template-columns: repeat(2, 1fr);
    }
    .ListAllCategories img {
      height: 300px !important;
    }
    .ListAllCategories>div>div:first-child
    {
      height: 300px !important;
    }
    }
    @media (max-width: 550px) {
      .ListAllCategories img {
        height: 270px !important;
      }
      .ListAllCategories>div>div:first-child
      {
        height: 270px !important;
      }
    }
    @media (max-width: 480px) {
      .ListAllCategories img {
        height: 220px !important;
      }
      .ListAllCategories>div>div:first-child
      {
        height: 220px !important;
      }
    }
    @media (max-width: 420px) {
      .series-seasons-container>div>button:last-child:before {
       display: none;
    }
      .series-seasons-numbers {
        background: #2e3192;
    }
      .ListAllCategories img {
        height: 210px !important;
      }
      .ListAllCategories>div>div:first-child
      {
        height: 210px !important;
      }
    }
    @media (max-width: 380px) {
      .ListAllCategories img {
        height: 190px !important;
      }
      .ListAllCategories>div>div:first-child
      {
        height: 190px !important;
      }
    }
    