.activeSelected div{
  color: var(--white) !important;
  border-bottom: 3px solid #fff;
  padding-bottom: 3px;
}

.FaIcon {
  margin-right: 5px;
  width: 32px;
  height: 27px;
}

.navBarSearch {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.navBarSearch>*+* {
  margin-left: 2px;
}
@media (max-width: 1400px) {
  .FaIcon {
    width: 30px;
    height: 25px;
  }
}