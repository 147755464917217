.ChannelContainer {
  display: flex;
  width: 92%;
  margin: auto;
  border-bottom: 1px solid #21212180;
  margin-top: 4%;
}

.ChannelPlayer {
  width: 60%;
  background-color: #000;
  border-bottom-right-radius: 10px;
}
.ChannelPlayer img{
  width: 100%;
}

.ChannelItemInfoWrapper {
  padding: 0px 2% 10px;
}

.ChannelItemTitle {
  color: #fff;
  font-size: 34px;
  font-weight: 400;
  margin-bottom: 15px;
}

.ChannelItemDesc {
  color: #f0f0f0;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
}

.ChannelItemInfo {
  display: flex;
}

.ChannelItemInfo p {
  color: #f0f0f0;
  font-size: 16px;
  margin-right: 30px;
}

.ChannelDayInfo {
  width: 40%;
  margin-top: -5px;
}

.ChannelDayWrapper {
  display: flex;
  background-color: #242427;
  justify-content: space-around;
}

.ChannelDayBTN {
  color: #d9d9d9;
  background-color: transparent;
  padding: 15px 0;
  width: 100%;
  border: none;
  border-radius: 0;
  font-size: 16px;
}

.active {
  color: #febd0e;
  background-color: #2f2f32;
  padding: 15px 0;
  width: 100%;
  border: none;
  font-size: 16px;
}

.DayInfoContainer {
  margin: 0;
  padding: 0;
  width: 100%;
  border-collapse: collapse;
  border-bottom-left-radius: 20px;
  background-color: #000;
}

.DayInfoContainer tr:nth-child(odd) {
  background-color: #19191a;
}

.DayInfoContainer tr:nth-child(even) {
  background-color: #1e1e20;
}

.noProgram p {
  text-align: center;
  color: #fff;
  padding: 30px;
}

.EachInfo {
  display: flex;
  justify-content: space-between;
  padding: 18px 25px;
  background: #000;
  border-bottom: 1px solid #fff;
}

.FirstInfo {
  display: flex;
  justify-content: space-between;
  padding: 18px 25px ;
  background-color: grey;
  position: relative;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
}

/* .FirstInfoBorder {
  background-color: #0370f9;
  height: 7px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 20px;
} */

.FirstInfo div {
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}

.activeTxT {
  font-size: 16px !important;
  color: #fff !important;
}

.EachInfo div {
  color: #fff;
}

.unactiveTxT {
  color: #fff;
}
.liveChannelsWrapper {
  margin: 30px 0;
}
.LiveChannelsContainer img {
  height: 190px;
}
.ChannelDayContainer{
  max-height: 340px;
  height: -moz-fit-content;
  height: fit-content;
  overflow-y: auto;
}
.ChannelMain{
  position: relative;
  margin-top: 20px;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  margin-left: 2%;
}
.ChannelMain::after{
position: absolute;
content: "";
background-image: url("../../images/down-arrow.png");
background-size: 25px;
background-position: center;
width: 25px;
height: 15px;
bottom: -22px;
left: 0;
right: 0;
margin: 0 auto;
}
.ChannelMain::before{
position: absolute;
content: "";
background-image: url("../../images/top-arrow.png");
background-size: 25px;
background-position: center;
width: 25px;
height: 15px;
top: -21px;
left: 0;
right: 0;
margin: 0 auto;
}
.nowPlay{
  font-weight: bold;
}
 .TimeInfo{
  width: 25%;
}
 .ShowName{
  width: 60% ;
  text-align: left;
}
 .PlayButton{
  width: 15%;
}
.TvStatus{
  color: white;
  border-bottom: 1px solid #fff;
  border-top: 1px solid #fff;
  padding: 10px 0;
  width: 69%;

}

.TVGuideEventItemTitle {
  display: flex;
  color: #fff;
  font-size: 15px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  justify-content: start;
  text-align: left;
  align-items: center;
  width: 100%;
}
.TVGuideEventItemTitle span{
width: 20%;
text-align: right;
}
.TVGuideEventItemTitle img{
width: 25px;
margin-top: 2px;
}
.TVGuideEventItemTitle p{
  margin: 10px 0;
font-size: 15px;
color: #fefefe;
}
.TVGuideEventItemTitle h6{
  font-size: 15px;
}
/* .TVGuideEventItemTitle .TVGuideEventItemTime{
width: 30%;
} */
.textLong{
  width: 75% !important;
  align-items: flex-start !important;
}
.tvGuideTitle{
  padding: 20px !important;
  align-items: flex-start !important;
  background: #333333;
}
.tvGuideTitle .PlayButton{
  display: none;
}
.HideButton{
  visibility: hidden;
}
.HideWatchReplay{
  display: none;
}
.watchReply{
  display: flex;
  align-items: center;
}
.watchReply img{
  margin-right: 20px;
}
.TVGuideChannelImageContainer {
  margin: 35px 0 10px;
  text-align: center;
  border-radius: 20px;
}
.channelDateBar h3{
  border-bottom: 3px solid #fff ;
  position: relative;
  display: inline-block;
}
.channelDateBar{
position: relative;
overflow: hidden;
}
.channelDateBar::before{
  content: "";
  height: 18px;
  width: 5%;
  background-color: #f7941d;
  right: -3%;
  transform: translate(-50%,-50%);
  top: 30px;
  position: absolute;
  z-index: 2;
}
.channelDateBar::after{
  content: "";
  height: 18px;
  width: 5%;
  background-color: #f7941d;
  left: 4%;
  transform: translate(-50%,-50%);
  top: 30px;
  position: absolute;
  z-index: 2;
}

@media (max-width: 1800px) {
  .ChannelDayContainer {
    max-height: 340px;
  }
}
@media (max-width: 1700px) {
  .LiveChannelsContainer img {
      height: 160px;
  }
  .ChannelDayContainer {
    max-height: 320px;
  }
}
@media (max-width: 1600px) {
  .TvStatus {
    padding: 5px 0;
    font-size: 15px;
}
  .ChannelPlayer {
    width: 58%;
}
.ChannelDayInfo {
  width: 42%;
}
  .ChannelItemInfoWrapper {
    padding: 0px 2% 0px;
}
  .ChannelItemTitle {
    font-size: 28px;
    margin-bottom: 8px;
}
  .FirstInfo div {
    font-size: 16px;
  }
  .FirstInfo {
    padding: 11px 20px 10px;
    background: grey;
  }
  .EachInfo div {
    font-size: 16px;
}
.EachInfo {
  padding: 10px 20px;
}
.ChannelDayContainer {
  max-height: 306px;
  height: 100%;
}
}
@media (max-width: 1500px) {
  .ChannelDayContainer {
    margin-top: 0px;
    max-height: 275px;
  }
}
@media (max-width: 1400px) {
  .ChannelDayContainer {
    max-height: 255px;
  }
  .LiveChannelsContainer img {
      height: 165px;
  }
}
@media (max-width: 1300px) {
  .ChannelDayContainer {
    max-height: 222px;
  }
  .TvStatus {
    padding: 5px 0;
    font-size: 13px;
}
  .ChannelPlayer {
    width: 55%;
}
.ChannelDayInfo {
  width: 45%;
}
.ChannelItemTitle {
  font-size: 24px;
  margin-bottom: 3px;
}
.FirstInfo div {
  font-size: 13px;
}
.FirstInfo {
  padding: 8px 15px 6px;
  background: grey;
}
.EachInfo div {
  font-size: 12px;
}
.EachInfo {
  padding: 6px 15px;
}
}

@media (max-width: 1200px) {
  .ChannelDayContainer {
    max-height: 202px;
  }
  .LiveChannelsContainer img {
      height: 150px;
  }
}
@media (max-width: 1100px) {
  .ChannelDayContainer {
    max-height: 165px;
  }
}

@media (max-width: 1023px) {
  .EachInfo {
    padding: 8px 15px;
}
  .FirstInfo {
    padding: 9px 15px 7px;
    background: grey;
}
  .ChannelDayContainer {
    max-height: 290px;
  }
  .ChannelPlayer {
    width: 100%;
}
.ChannelDayInfo {
  width: 100%;
  margin-top: 30px;
}
  .LiveChannelsContainer img {
      height: 140px;
  }
  .ChannelContainer {
    flex-direction: column;
}
}
@media (max-width: 949px) {
  .LiveChannelsContainer img {
      height: 155px;
  }
}
@media (max-width: 767px) {
 
  .LiveChannelsContainer img {
      height: 140px;
  }
  .ChannelDayContainer {
    max-height: 240px;
  }
}
@media (max-width: 580px) {
  .ChannelDayContainer {
    max-height: 200px;
  }
  .ChannelItemTitle {
    font-size: 18px;
}
}
@media (max-width: 480px) {
  .ChannelMain::after{
    background-size: 20px;
    width: 20px;
    height: 10px;
    }
  .ChannelMain::before{
    background-size: 20px;
    width: 20px;
    height: 10px;
    }
  .FirstInfo {
    padding: 7px 10px;
    background: grey;
}
.EachInfo{
  padding: 7px 10px;
}
  .ChannelDayContainer {
    max-height: 170px;
  }
  .TvStatus {
    padding: 6px 0;
    font-size: 11px;
    width: 85%;
}
  .FirstInfo p{
    font-size: 10px;
  }
  .EachInfo p{
    font-size: 11px;
  }
}

@media (max-width: 400px) {
  .LiveChannelsContainer img {
      height: 110px;
  }
  .ChannelDayContainer {
    max-height: 170px;
  }
}
