.RadiantPlayer {
  /* position: fixed;
  overflow: hidden;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000; */
  width: 100%;
  /* padding: 20px 0; */
  /* margin: 0 auto; */
}
.RadiantPlayer .rmp-control-bar {
  /* margin-top: -200px; */
}
.PlayerReactContainer{
  width: 92%;
  margin: 0 auto;
  height: 91vh;
}
.react_Player>div{
  width: 92% !important;
  height: 85vh !important;
  margin: auto;
}
@media (max-width: 1023px) {
  .PlayerReactContainer {
    height: 540px;
}
.react_Player>div {
  height: 70vh !important;
}
}
@media (max-width: 900px) {
  .PlayerReactContainer {
    height: 470px;
}
}
@media (max-width: 800px) {
  .PlayerReactContainer {
    height: 400px;
}
}
@media (max-width: 767px) {
  .react_Player>div {
    height: 45vh !important;
}
}
@media (max-width: 700px) {
  .PlayerReactContainer {
    height: 360px;
}
}
@media (max-width: 580px) {
  .PlayerReactContainer {
    height: 320px;
}
}
@media (max-width: 480px) {
  .PlayerReactContainer {
    height: 280px;
}
}
@media (max-width: 400px) {
  .PlayerReactContainer {
    height: 250px;
}
}
@media (max-width: 360px) {
  .PlayerReactContainer {
    height: 220px;
}
}