/* DONT DELTE OR MODIFY THEM UNDER NO CIRCUMSTANCE: INCLUDES CUSTOMER CUSTOM RULES */

body {
  /*font-family: 'Rubik', sans-serif;*/
  font-family: 'Roboto', sans-serif;
  letter-spacing: .5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  max-width: 100vw;
}

@font-face {
  font-family: 'Gotham Greek Medium';
  /* src: url('/src/fonts/GothamGRLight.otf') format('opentype'); */
  src: url('/src/fonts/GothamGRMedium.otf') format('opentype');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* // radiant media player advert background */
.rmp-ad-info-message {
    background-color: white !important;
}

.thin {
  font-family: 'Gotham Greek Light';
}

.thin-book {
  font-family: 'Gotham Greek Book';
}

:root {
  /* --background: #12161d;
  --cardbackground: rgba(0, 0, 0, 0.3);
  --card: #1e2228;
  --cardStroke: #2b3037;
  --darkGray: #2f353d;
  --gray: #808890;
  --blueSilver: #d2dcdf;
  --silver: #e5edf0;
  --white: #fdfdfd;
  --brand: #2671fe;
  --brandHover: #c5d920;
  --brandPressed: #172bb5;
  --red: #be3037;
  --disabledText: #a1a9b0;

  --itemTitle: #e5edf0;
  --categorytitle: #fdfdfd;

  --inactive: rgba(229, 237, 240, 0.5); */


  --background: #12161d;
  --cardbackground: rgba(0, 0, 0, 0.3);
  --card: #1e2228;
  --cardStroke: #2b3037;
  --darkGray: #2f353d;
  --gray: #808890;
  --blueSilver: #d2dcdf;
  --silver: #febd0e;
  --white: #fdfdfd;
  --brand: #2671fe;
  --brandHover: #fdfdfd;
  --brandPressed: #172bb5;
  --red: #be3037;
  --disabledText: #a1a9b0;

  --itemTitle: #e5edf0;
  --categorytitle: #fdfdfd;

  --inactive: rgba(229, 237, 240, 0.5);

  --font40: 40px;
  --font39: 39px;
  --font38: 38px;
  --font37: 37px;
  --font36: 36px;
  --font35: 35px;
  --font34: 34px;
  --font33: 33px;
  --font32: 32px;
  --font31: 31px;
  --font30: 30px;
  --font29: 29px;
  --font28: 28px;
  --font27: 27px;
  --font26: 26px;
  --font25: 25px;
  --font24: 24px;
  --font23: 23px;
  --font22: 22px;
  --font21: 21px;
  --font20: 20px;
  --font18: 18px;
  --font16: 16px;
  --font15: 15px;
  --font14: 14px;
  --font14: 14px;
  --font13: 13px;
  --font12: 12px;
  --font11: 11px;
  --font10: 10px;
  --font9: 9px;
  --font8: 8px;
  --font7: 7px;
  --font6: 6px;
  --font5: 5px;
}

.itemTitleHeadingColor {
  color: #f7f8fa;
}

.itemLinksColor {
  color: var(--brand);
}

.font-800 {
  font-size: var(--font40);
  font-weight: 600;
  opacity: 1 !important;
  margin-bottom: 20px;
}

.font-700 {
  font-size: var(--font36);
}

.font-600 {
  font-size: var(--font24);
}

.font-500 {
  font-size: var(--font20);
  line-height: 20px;
}

.font-400 {
  font-size: var(--font18);
}

.font-300 {
  font-size: var(--font13);
}

.font-200 {
  font-size: var(--font14);
}

.font-150 {
  font-size: 13px;
}

.font-100 {
  font-size: var(--font12);
}

.font-000 {
  font-size: var(--font11);
}

/* modal */

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 90;
  background-color: var(--cardbackground);
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin: 0 auto;
  transform: translateY(0rem);
}

.modal>h2 {
  padding: 20px;
  margin: 0;
  color: var(--brand);
  /* border-bottom: 1px solid #555; */
}

.copy-popup {
  background: var(--card);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 12px;
  transition: transform 0.5s ease;
  transform: translateY(-2rem);
  min-width: 40%;
  min-height: 40vh;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: var(--cardbackground);
  z-index: 100;
  transition: all 0.5s ease-in-out;
}

.embed-link {
  /* font-size: 2.5rem; */
  padding: 10px;
  background-color: var(--background);
  border: 0;
  border: 1px solid var(--brand);
  width: 80%;
  margin: 0 auto;
  height: 20vh;
  color: var(--silver);
}

.embed-link:focus {
  outline: none;
}

.copy-container {
  position: fixed;
  top: 0%;
  left: 0%;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  align-items: center;
  opacity: 0;
  pointer-events: none;
}

.copy-container.active {
  opacity: 1;
  pointer-events: all;
}

.copy-popup.active {
  transform: translateY(0rem);
}

h2 {
  font-size: var(--font24);
  margin-bottom: 15px;
  display: block;
  text-align: center;
  font-weight: 400;
  padding-top: 20px;
  padding-bottom: 20px;
}

h1,
h3 {
  font-size: var(--font36);
  margin-bottom: 15px;
  display: block;
  text-align: center;
  font-weight: 300;
  padding-top: 20px;
}

/* h2::after,
h1::after {
  content: '';
  width: 120px;
  height: 3px;
  top: -1rem;
  display: block;
  background-color: var(--brand);
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
} */

.display-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
}
.rmp-i-play::before {
  color: #f7941e;
}
.rmp-i-pause::before {
  color: #f7941e;
}
.asset-date {
  /* margin: 5px 0; */
  margin-top: 20px;
  padding-top: 20px;
  color: var(--brand);
  line-height: 140%;
}

/* RMP CONTAINER */
#rmpLivePlayer {
  max-height: 540px !important;
}
.rmp-mobile-central-ui .rmp-i-play::before {
  color: #f7941d !important;
}
.rmp-i-play::before {
  color: #fff !important;
}
.rmp-i-pause::before {
  color: #f7941d !important;
}
.rmpContainer {
  height: 100vh !important;
  width: 100vw !important;
}
.rmp-mobile-central-ui .rmp-i-pause, .rmp-mobile-central-ui .rmp-i-play, .rmp-mobile-central-ui .rmp-i-replay, .rmp-overlay-button .rmp-i-pause, .rmp-overlay-button .rmp-i-play, .rmp-overlay-button .rmp-i-replay {
  font-size: 60px !important;
}
.rmp-mobile-central-ui {
  top: 50%;
  left: 50%;
  right: 50%;
  height: 70px !important;
  margin-top: -30px;
  width: 70px !important;
  transform: translate(-20%, -1%) !important;
  margin-left: -20px !important;
}
.rmp-mobile .rmp-seek-bar {
  left: 29% !important;
  width: 49% !important;
}
.rmp-desktop-volume-icon {
  left: 4% !important;
}
.rmp-seek-bar {
  left: 29% !important;
  width: 49% !important;
}
.rmp-control-bar .rmp-i {
  width: 2% !important;
}
.rmp-control-bar  .rmp-i-pause::before {
  color: #fff !important;
}

.rmp-play-pause {
  left: 1% !important;
}
.rmp-medium .rmp-time-elapsed {
  left: 15% !important;
  width: 10% !important;
}
.channelsLive .rmp-medium .rmp-time-elapsed {
  left: 16% !important;
}
.rmp-time {
  left: 14% !important;
}
@media (max-width: 500px) {
  .rmp-time {
    left: 0% !important;
  }
}
.rmp-quality {
  right: 8% !important;
}
.rmp-volume {
  left: 7% !important;
}
.rmp-duration {
  left: 82% !important;
  width: 10% !important;
}
 .rmp-fullscreen {
  left: 96% !important;
  top: -2px !important;
}
/* Profile Section */
.activeProfileHeader {
  color: var(--brand);
}

.inactiveProfileHeader::after {
  display: none;
}

.inactiveProfileHeader {
  color: var(--inactive);
}

.inactiveProfileHeader:hover {
  color: var(--brandHover);
  cursor: pointer;
}

button {
  padding: 10px 56px;
  text-decoration: none;
  border: none;
  font-size: var(--font16);
  border-radius: 12px;
}

button:hover {
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

button:disabled {
  background: var(--gray);
  color: var(--blueSilver);
  cursor: not-allowed;
}

button:disabled:hover {
  background: var(--gray);
  color: var(--blueSilver);
  cursor: not-allowed;
}

.react-multiple-carousel__arrow {
  padding: inherit;
  z-index: 1000 !important;
  background: transparent !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(1% + 1px);
}

.react-multiple-carousel__arrow--left {
  left: calc(1% + 1px);
}

.categoriesContainer .react-multiple-carousel__arrow--right,
.categoriesContainer .react-multiple-carousel__arrow--left {
  top: 30%;
}

.react-multiple-carousel__arrow--right::before {
  content: "" !important;
  background-image: url("./images/right-arrow.png");
  /* width: 40px; */
  height: 40px !important;
  background-repeat: no-repeat;
}

.react-multiple-carousel__arrow--left::before {
  content: "" !important;
  background-image: url("./images/left-arrow.png");
  /* width: 40px; */
  height: 40px !important;
  background-repeat: no-repeat;
}

/* // banner slider custom button */
.carousel.carousel-slider .control-arrow {
  top: 0;
  color: #fff;
  font-size: 26px;
  bottom: 0 !important;
  margin-top: 0 !important;
  padding: 5px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  top: 50% !important;
  min-width: 43px !important;
  min-height: 43px !important;

  height: 43px !important;
  border-radius: 50% !important;
  background: #7d7a79 !important;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before,
.carousel .control-prev.control-arrow:before {
  margin: 0 !important;
  font-family: 'revicons' !important;
}

.carousel .control-next.control-arrow:before {
  content: '\e825' !important;
  border: none !important;
}

.carousel .control-prev.control-arrow:before {
  content: '\e824' !important;
  border: none !important;
}

/* changing right and left button in banner color */
/* These are not banner carousel */
.carousel.carousel-slider .control-arrow:hover {
  color: var(--brand) !important;
  background: rgba(0, 0, 0, 0.8) !important;
}

.carousel .slide {
  padding: 0 20px;
}

.carousel .selected .bannerImage {
  display: inline-block;

  background: linear-gradient(250deg, rgba(18, 22, 29, 0) 40%, #12161d 100.47%);
}

.hero-container {
  /* width: 95%; */
  margin: 30px auto 0;
  background-color: #000;
}

.liveChannels {
  position: relative;
  z-index: 100;
}

 .react-multi-carousel-list {
  width: 92% !important;
  margin: 0 auto !important;
}
 .MoviesBanner .react-multi-carousel-list {
  width: 100% !important;
  margin: 0 auto !important;
}
.bannerContainer .react-multi-carousel-list {
  width: 100% !important;
  margin: 0 auto !important;
}
.tvShowSlider .react-multiple-carousel__arrow {
  top: calc(50% - 43px);
}
.playMovieSlider .react-multiple-carousel__arrow {
  top: calc(50% - 50px);
}
.MoviesSlider .react-multiple-carousel__arrow {
  top: calc(50% - 50px);
}
.channelsLive .react-multiple-carousel__arrow {
  top: calc(50% - 50px);
}
.onnow-livechannel .react-multiple-carousel__arrow {
  top: calc(50% - 43px);
}
.liveOn{
  margin-top: -150px;
}
.bannerContainer .react-multiple-carousel__arrow--right::before {
  content: "" !important;
  background-image: url("./images/bxs_right-arrow.png");
  height: 60px !important;
  background-repeat: no-repeat;
}
.bannerContainer .react-multiple-carousel__arrow--left::before {
  content: "" !important;
  background-image: url("./images/bxs_left-arrow.png");
  height: 60px !important;
  background-repeat: no-repeat;
}
.bannerContainer .react-multiple-carousel__arrow--right {
    right: calc(0% + 33px) !important;
}
.bannerContainer .react-multiple-carousel__arrow--left {
    left: calc(0% + 40px) !important;
}
.homeContainer .react-multiple-carousel__arrow {
  top: calc(50% - 39px);
}
.TvGuideSection .react-multiple-carousel__arrow {
  top: 145px;
}
.onnow-livechannel {
  background-color: #000;
}

.react-multi-carousel-list {
  overflow: visible !important;
}

.react-multi-carousel-list::after {
  position: absolute;
  content: "";
  width: 16%;
  height: 100%;
  top: 0;
  /* right: 0; */
  bottom: 0;
  background: #000;
  z-index: 1;
  left: 99.9%;
}

.react-multi-carousel-list::before {
  position: absolute;
  content: "";
  width: 16%;
  height: 100%;
  top: 0;
  right: 100%;
  bottom: 0;
  background: #000;
  z-index: 1;
  /* left: 0; */
}

/* // image wrapper for aspect-ratio 16/9
 */
.image_wrapper {
  position: relative;
  padding-bottom: 56.25%;
}

/* .image_wrapper_cover {
  width: 215px;
  height: 300px;
} */

.image_wrapper img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* .react-multi-carousel-list {
  width: 94% !important;
  margin: 0 0 0 6% !important;
} */

/* media Queeries */

@media (min-width: 1024px) {
  #rmpPlayer {
    height: calc(100vh - 100px) !important;
  }
}
@media (max-width: 850px) {

  /* .font-800 {
    font-size: var(--font24);
  }
  .font-700 {
    font-size: var(--font22);
  }
  .font-600 {
    font-size: var(--font20);
  }

  .font-500 {
    font-size: var(--font16);
  }

  .font-400 {
    font-size: var(--font14);
  }

  .font-300 {
    font-size: var(--font13);
  }
  .font-200 {
    font-size: var(--font10);
  } */
  .hero-container {
    width: 100%;
  }

  /* .react-multiple-carousel__arrow--right {
    right: calc(4% + 1px) !important;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(4% + 1px) !important;
  } */
}

@media (max-width: 700px) {
  /* .font-800 {
    font-size: var(--font20);
  }
  .font-700 {
    font-size: var(--font16);
  }
  .font-600 {
    font-size: var(--font14);
  }

  .font-500 {
    font-size: var(--font13);
  } */
}

/* media Queeries */
@media (max-width: 600px) {
  .react-multiple-carousel__arrow::before {
    font-size: 16px !important;
  }

  .react-multiple-carousel__arrow {
    top: calc(50% - 38px);
  }

  /* .font-800 {
    font-size: var(--font24);
  }
  .font-700 {
    font-size: var(--font22);
  }
  .font-600 {
    font-size: var(--font20);
  }

  .font-500 {
    font-size: var(--font16);
  }

  .font-400 {
    font-size: var(--font14);
  }

  .font-300 {
    font-size: var(--font13);
  }
  .font-200 {
    font-size: var(--font10);
  } */

  /* button {
    padding: 18px 20px;
  } */
}

.homeContainer1 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.react-multi-carousel-item:not(.react-multi-carousel-item--active) .overlay_blur {
  background-image: linear-gradient(270deg,
      #12161d 0%,
      rgba(18, 22, 29, 0.08) 100%);

  opacity: 1;

  z-index: 100000;
}

.react-multi-carousel-item:not(.react-multi-carousel-item--active) .playicon_overlay {
  display: none;
}

.react-multi-carousel-dot-list {
  bottom: 15% !important;
  left: calc(0% - -140px) !important;
  right: 95% !important;
  z-index: 100;
}

.react-multi-carousel-dot button {
  width: 13px !important;
  height: 13px !important;
  border-color: #d2dcdf !important;
}

.react-multi-carousel-dot--active button {
  background: #999999 !important;
  width: 30px !important;
  height: 13px !important;
  border-radius: 50px !important;
  border-color: #fff !important;
}

.carousel .control-prev.control-arrow {
  left: 05px !important;
}

.carousel .control-next.control-arrow {
  right: 05px !important;
}

/* // navigation mobile menu */
ul.main-menu {
  display: inline-flex;
  list-style: none;
  z-index: 7;
}

.menu-item a {
  position: relative;
  display: block;
  color: var(--silver);
  text-decoration: none;
  cursor: pointer;
}

.sub__menus__arrows {
  position: relative;
}

.sub__menus__arrows svg,
.sub__menus__arrows__full svg {
  padding-top: 0px;
  position: absolute;
  top: 13px;
}

.sub__menus {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid lavender;
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}

/* // react toastify */
.Toastify__toast-theme--dark {
  margin-top: 120px !important;
}

/* // slider adjust  */
.addSeecondary ul {
  transform: none !important;
}

/* Season active classname */
.activeSeason {
  background: #2e3192 !important;
  color: #fff !important;
  position: relative;
}
.activeSeason::after, .seasonNumber:hover::after {
  content: "";
  position: absolute;
  background: #f7941d;
  width: 40%;
  height: 3px;
  bottom: 7px;
  right: 30%;
  border-radius: 3px;
}
.border-img img{
  border: 2px solid #f7941d;
}
.NowPlay {
  font-size: 13px;
  font-style: italic;
  font-weight: bold;
  color: #fff;
}
.NowPlay .PlayDot{
  font-size: 50px;
    line-height: 0;
}
.series-seasons-container{
  position: relative;
}
.series-seasons-container>div>button:last-child:before {
  position: absolute;
  z-index: 1;
  content: "";
  right: -14%;
  top: 0;
  height: 100%;
  width: 50px;
  background-color: #2e3192;
  transform: skewX(-45deg);
}
/* // language dropdown selected
activeSelected */
.activeSelected {
  color: var(--brandHover);
}

.activeSelected:hover {
  color: var(--brand);
}

.action {
  display: flex;
  cursor: pointer;
}

.action>*+* {
  margin-left: 30px;
}

.languageSelectMobile {
  border-top: 1px solid var(--brand);
  width: 90%;
  margin: 10px auto;
  padding-top: 20px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 !important;
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + -46px) !important;
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + -25px) !important;
}
@media (min-width: 1400px) {
  .rmp-desktop-volume-icon {
    left: 5% !important;
}
  .liveChannel{
     padding: 30px 7px 15px !important;
 }
 }
@media (max-width: 1600px) {
  .TvGuideSection .react-multiple-carousel__arrow {
    top: 115px;
  }
  .react-multi-carousel-dot-list {
    left: calc(0% - -108px) !important;
}
}
@media (max-width: 1400px) {
  .bannerContainer .react-multiple-carousel__arrow--right {
    right: calc(0% + 23px) !important;
}
.bannerContainer .react-multiple-carousel__arrow--left {
    left: calc(0% + 30px) !important;
}
  /* .rmp-desktop-volume-icon {
    left: 6% !important;
}
  .rmp-seek-bar {
    left: 18% !important;
    width: 68% !important;
}
  .rmp-mobile .rmp-seek-bar {
    left: 18% !important;
    width: 70% !important;
}
.rmp-duration {
  left: 88% !important;
  width: 9% !important;
}
.rmp-fullscreen {
  left: 96% !important;
}
.rmp-medium .rmp-time-elapsed {
  left: 8% !important;
  width: 8% !important;
} */
}
@media (max-width: 1140px) {
  .bannerContainer .react-multiple-carousel__arrow--right {
    right: calc(0% + 13px) !important;
}
.bannerContainer .react-multiple-carousel__arrow--left {
    left: calc(0% + 20px) !important;
}
}
@media (max-width: 1080px) {
   .TvGuideSection .react-multiple-carousel__arrow {
    top: 106px;
  }
}
@media (min-width: 992px) {

  .sub__menus__arrows:hover .sub__menus {
    display: block;
  }

  .sub__menus__arrows__full:hover .sub__menus {
    display: block;
    z-index: 1000000;
  }
}

.menu-item .sub__menus a {
  padding: 7px 0 0px 0;
  margin: 7px 27px;
}

.menu-item:hover>a {
  color: var(--brandHover) !important;
}

.menu-item .sub__menus li:hover a {
  color: var(--brandHover) !important;
}

.navBarSiteLogoMobile {
  display: none;
}

.navBarSiteLogoMobile-lgScreen {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1023px) {
  .bannerContainer .react-multiple-carousel__arrow--right {
    right: calc(0% + 3px) !important;
}
.bannerContainer .react-multiple-carousel__arrow--left {
    left: calc(0% + 10px) !important;
}
  .react-multi-carousel-dot-list {
    left: calc(0% - -10%) !important;
}
}
@media (max-width: 991px) {
  .react-multi-carousel-dot-list {
    bottom: 19% !important;
  }

  .navBarSiteLogo {
    display: none;
  }

  .navBarSiteLogoMobile-lgScreen {
    /* display: none; */
  }

  .navBarSiteLogoMobile {
    display: flex;
    margin: 20px 0;
    flex: 1;
    justify-content: left;
    align-items: center;
  }

  .navBarSiteLogoMobileFooter {
    margin: 0;
  }

  .action {
    flex: 1;
    justify-content: flex-end;
  }

  .action .navBarSiteLogoMobile {
    flex: 0;
  }

  .navBarSiteLogoMobile-img {
    margin-left: 5px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-nav .menubar__button:hover {
    background-color: var(--brand);
    /* color: var(--brandHover);

     */
    cursor: pointer;
  }

  .main-nav .menu-item:hover>a {
    color: var(--brandHover) !important;
  }

  .main-nav .menubar__button {
    display: block !important;
    float: right;
    /* background-color: var(--brand); */
    color: #fff;
    padding: 6px 7px;
    border-radius: 5px;
    margin: auto 0;
    cursor: pointer;
    position: relative;
    z-index: 10037;
  }

  .main-nav .menubar__button svg {
    font-size: 27px;
  }

  .main-nav ul.menuq2 {
    display: flex !important;
    position: absolute;
    top: 49px;
    bottom: 0px;
    height: 60px;
    width: 95%;
    z-index: 10007;
    background-color: transparent;
    right: 2%;
    grid-gap: 10px;
    grid-gap: 10px;
    gap: 10px;
}

  .main-nav ul.main-menu {
    display: none;
  }

  .main-nav .sub__menus__arrows:hover .sub__menus {
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .sub__menus__Active {
    display: block !important;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .sub__menus__Active2 {
    display: block !important;
  }

  .menus__categorysss {
    display: none;
  }

  .menus__categorysss2 {
    display: none;
  }

  .menu-item .sub__menus__full a {
    text-align: left;
  }

  .ratingElement {
    width: 40px;
  }
  /* .action>*+* {
    margin-left: 15px;
  } */
}
.slick-center h3{
  border-bottom: 3px solid #fff ;
  position: relative;
  display: inline-block;
}

@media (max-width: 767px) {
  .react-multi-carousel-dot-list {
    bottom: 25% !important;
}
  .rmp-desktop-volume-icon {
    left: 8% !important;
}
  .rmp-medium .rmp-time-elapsed {
    left: 14% !important;
    width: 10% !important;
  }
  .rmp-seek-bar {
    left: 25% !important;
    width: 58% !important;
}
.rmp-mobile .rmp-seek-bar {
  left: 32% !important;
  width: 41% !important;
}
.replyPlayer .rmp-seek-bar {
  left: 35% !important;
  width: 40% !important;
  top: 4px !important;
}
  .rmp-mobile-central-ui {
    height: 40px !important;
    width: 40px !important;
}
  .rmp-mobile-central-ui .rmp-i-play {
    font-size: 30px !important;
}
  .rmp-mobile-central-ui .rmp-i-pause {
    font-size: 30px !important;
}
.rmp-mobile-central-ui .rmp-i-replay {
    font-size: 30px !important;
}

.rmp-fullscreen {
  left: 95% !important;
}
.rmp-duration {
  left: 79% !important;
  /* width: 10% !important; */
}
  /* .rmp-mobile .rmp-seek-bar {
  left: 24% !important;
    width: 58% !important;
}
.rmp-duration {
  left: 84% !important;
  width: 11% !important;
}
.rmp-fullscreen {
  left: 95% !important;
} */
.rmp-time-total {
  top: 15px !important;
  height: 11px !important;
}
.rmp-handle {
  margin-top: -11px !important;
  width: 21px !important;
  height: 21px !important;
} 
  .react-multiple-carousel__arrow {
    display: none;
  }
  .react-multi-carousel-dot button {
    width: 10px !important;
    height: 10px !important;
  }

  .react-multi-carousel-dot--active button {
    width: 22px !important;
    height: 10px !important;
  }
}

@media (max-width: 580px) {
  .replyPlayer .rmp-seek-bar {
    left: 43% !important;
    width: 35% !important;
}
.replyPlayer .rmp-quality {
  right: 12% !important;
}
.replyPlayer .rmp-control-bar .rmp-i {
  width: 8% !important;
}
.replyPlayer .rmp-fullscreen {
  left: 90% !important;
}
  .react-multi-carousel-dot-list {
    left: calc(0% - -14%) !important;
  }
  .slick-center h3 {
    border-bottom: 2px solid #ffff !important;
  }
  .react-multi-carousel-dot-list {
    bottom: 26% !important;
  }
}

@media (max-width: 550px) {
  .copy-popup {
    min-width: 94% !important;
    margin: 0 auto;
    min-height: 50vh;
  }

  .embed-link {
    width: 94%;
  }

  .ratingElement {
    width: 30px;
  }

  .carousel .slide {
    padding: 0;
  }

  .carousel .slide img {
    height: 211px;
  }

  .navBarSiteLogoMobile-img {
    margin-left: 0px;
    height: 30px;
  }

  .font-600 {
    font-size: 18px;
  }

  .font-300 {
    font-size: var(--font12);
  }

  .font-200 {
    font-size: var(--font11);
  }

  .asset-date {
    padding-top: 5px;
  }

  .font-700 {
    font-size: 18px;
  }

  .carousel .selected .bannerImage {
    background: linear-gradient(180deg,
        rgba(18, 22, 29, 0) 73.72%,
        #12161d 100%);
  }
}
@media (max-width: 475px) {
  .rmp-desktop-volume-icon {
    left: 13% !important;
}
  .rmp-mobile .rmp-seek-bar {
    left: 5% !important;
    width: 81% !important;
}
  .rmp-extrasmall .rmp-duration, .rmp-small .rmp-duration {
    width: 18% !important;
}
.rmp-fullscreen {
  left: 92% !important;
}
.rmp-control-bar .rmp-i {
  font-size: 20px !important;
}
.replyPlayer .rmp-seek-bar {
  left: 28% !important;
  width: 40% !important;
}
.replyPlayer .rmp-volume {
  left: 13% !important;
}
}
@media (max-width: 767px) {
  .categoryItem {
    margin: 0.5rem 3px !important;
  }
}