.categoriesContainer {
  /* width: 95%; */
  /* margin: 0 0 0 5%; */
}

.loaderContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
}
