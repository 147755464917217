.TVGuideContainer {
  /* width: 88%; */
  margin: auto
}

.TVGuideHeadContainer {
  /* display: flex; */
  justify-content: center;
  background-color: #404045;
  width: 92%;
  margin: 40px auto 5px;
}


.TVGuideContainer p {
  color: #fff;
  font-size: 22px;
}

.TVGuideDataContainer {
  background-color: #000;
}

.TVGuideEventList ul {
  list-style-type: none;
}

.TVGuideEventItemWrapper {
  border-bottom: 1px solid #afafaf;
  position: relative;
  width: 96%;
  margin: 0 auto;
}

.TVGuideEventList li:last-child .TVGuideEventItemWrapper {
  border-bottom: none;
}

.TVGuideEventItemTitle {
  display: flex;
  color: #fff;
  font-size: 15px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  /* justify-content: space-around; */
  text-align: left;
  width: 100%;
  margin: 0 auto;
  align-items: center;
}
.TVGuideEventItemTitle span{
width: 20%;
text-align: right;
}
.TVGuideEventItemTitle .PlayImg{
width: 25px;
margin-top: 2px;
}
.TVGuideEventItemTitle p{
  margin: 20px 0;
font-size: 15px;
color: #fefefe;
}
.TVGuideEventItemTitle h6{
  font-size: 15px;
}
.TVGuideEventItemTitle .TVGuideEventItemTime{
width: 30%;
}
.TVGuideEventItemTitle .TVGuideEventItemText{
width: 50%;
}
.textLong{
  width: 70% !important;
  align-items: flex-start !important;
}
.tvGuideTitle{
  align-items: flex-start !important;
}
.watchReply{
  display: flex;
  align-items: center;
}
.watchReply img{
  margin-right: 20px;
}
.TVGuideEventItemTitle:nth-child(even) {
  background-color: red;
  /* background-color: #1d1d1f; */
}

.TVGuideEventItemTime {
  padding-right: 10px;
}

.TVGuideEventItemText {
  padding-right: 10px;
}

.TVGuideEventItemContent {
  background-color: #414146;
  width: 100%;
  padding: 15px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  color: #fff;
  font-size: 14px;
  position: absolute;
  z-index: 1;
}

.TVGuideEventItemtext1 {
  display: flex;
  cursor: pointer;
}

.TVGuideEventDescription {
  margin-top: 20px;
}

.TVGuideEventDescription p {
  text-align: start;
  margin: 5px 10px;
  font-size: 14px;
}

.TVGuideChannelImageContainer {
  margin: 35px 0 10px;
  text-align: center;
  border-radius: 20px;
}

.TVGuideChannelImageWrapper {
  display: flex;
  align-items: center;
  background-color: #febd0e;
  border-radius: 20px;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.TVGuideChannelImageWrapper p {
  color: #000;
  padding-left: 10%;
  font-size: 20px;
  text-align: left;
}

.TVGuideChannelImage {
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
  /* height: 130px; */
  width: 100%;
}

.TVGuideChannelImage img {
  aspect-ratio: 16/10;
  border-radius: 20px;
  object-fit: contain;
  width: 100%;
  object-position: center;
  height: 100%;
  transform: scale(1.5);
}

.CurrentEventInfo {
  padding: 20px 0;
}

.CurrentEventInfo h6 {
  color: #febd0e;
  font-size: 20px;
  font-weight: 100;
}

.CurrentEventInfo p {
  color: #fff;
  font-size: 14px;
  padding-top: 20px;
}

.TVGuideChannelImageborder {
  background-color: #0370f9;
  height: 7px;
  border-bottom-left-radius: 20px;
}

.TVGuideImgBTN {
  text-align: end;
  margin-right: 20px;
}
.downArrow{
  position: absolute;
  right: 5px;
}
.WhatDateContainer{
  background: red;
}
.WhatDate{
  width: 100%;
  background: #000;
  display: flex !important;
  align-items: center;
  justify-content: space-around ;
  position: relative;
  text-align: center;
}
.TVGuideHeadContainer .WhatDate{
  justify-content: center ;
}
.TVGuideHeadContainer::after{
  content: "";
  height: 18px;
  width: 3%;
  background-color: #f7941d;
  right: 2%;
  transform: translate(-50%,-50%);
  top: 34px;
  position: absolute;
}
.TVGuideHeadContainer::before{
  content: "";
  height: 18px;
  width: 3%;
  background-color: #f7941d;
  left: 5%;
  transform: translate(-50%,-50%);
  top: 34px;
  position: absolute;
  z-index: 2;
}
/* .WhatDate::after{
  content: "";
  height: 18px;
  width: 36px;
  background-color: #f7941d;
  right: -50px;
  transform: translate(-50%,-50%);
  top: 52%;
  position: absolute;
} */
.TVGuideHeadContainer .WhatDate::after{
  width: 50px;
}

.TVGuideHeadContainer  .TVGuideHeaderTitle {
  padding-bottom: 5px;
  color: #fff;
  font-size: 22px !important;
  padding-bottom: 5px;
}
.WhatDate> div{
  width: 95%;
}
.TVGuideHeadContainer .WhatDate> div{
  width: 60%;
}
.todaysGoal h3{
  color: white;
  font-size: 18px;
  white-space: nowrap;
  font-weight: bolder;
}
.todaysGoal h3::after {
  position: absolute;
  content: "";
  width: 80%;
  height: 4px;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  color: #fff;
  z-index: 14444444444440000;
}
.previousDate h4{
  color: white;
  font-size: 16px;
  white-space: nowrap;
  font-weight: bolder;
  display: inline-block;
}
.dateEnd h4{
  color: white;
  font-size: 16px;
  white-space: nowrap;
  font-weight: bolder;
  display: inline-block;
}
.arrowNext{
  position: absolute;
  z-index: 10;
  right: 28%;
  top: 23px;
}
.TVGuideHeadContainer .arrowNext1{
  position: absolute;
  z-index: 10;
  right: 37%;
  top: 23px;
}
.TVGuideHeadContainer .arrowNext{
  right: 18%;
}
.arrowPrev{
    position: absolute;
    z-index: 10;
    left: 28%;
    top: 23px;
}
.TVGuideHeadContainer .arrowPrev1{
    position: absolute;
    z-index: 10;
    left: 37%;
    top: 23px;
}
.TVGuideHeadContainer .arrowPrev{
    left: 18%;
}
.TVGuideEventList ul, .TVGuideEventList li{
  margin: 0 0.25rem;
}
.TVGuideBackColor{
  background: #333333 !important;
}
.HidePlayButton{
  visibility: hidden;
}
.HideWatchReplay{
  display: none;
}
@media (max-width: 1700px) {
  .todaysGoal h3 {
    font-size: 16px;
}
  .previousDate h4 {
    font-size: 16px;
  }
  .dateEnd h4 {
    font-size: 16px;
  }
  .TVGuideHeadContainer  .TVGuideHeaderTitle {
    font-size: 20px !important;
}
}
@media (max-width: 1600px) {
  /* .TVGuideChannelImage {
    height: 140px;
} */
.arrowNext img{
  width: 58px;
}
.arrowPrev img{
width: 58px;
}
  .TVGuideEventItemTitle h6{
    font-size: 13px;
}
  .TVGuideEventItemTitle h4{
    font-size: 13px;
}
  .TVGuideEventItemTitle p{
    font-size: 13px;
}
  .arrowNext {
    top: 21px;
}
  .TVGuideHeadContainer .arrowNext1 {
    top: 21px;
}
.arrowPrev{
  top: 21px;
}
.TVGuideHeadContainer  .TVGuideHeaderTitle {
    font-size: 18px !important;
}
.previousDate h4 {
  font-size: 15px;
}
.dateEnd h4 {
  font-size: 15px;
}
}
@media (max-width: 1500px) {
  .todaysGoal h3 {
    font-size: 15px;
}
}
@media (max-width: 1400px) {
  .todaysGoal h3 {
    font-size: 14px;
}
  .TVGuideHeadContainer::after {
    top: 31px;
}
  .TVGuideHeadContainer::before {
    top: 31px;
}
  /* .TVGuideChannelImage {
    height: 130px;
} */
  .arrowNext img{
    width: 50px;
}
  .TVGuideHeadContainer .arrowNext1 img{
    width: 50px;
}
.arrowPrev img{
  width: 50px;
}
.TVGuideHeadContainer .arrowPrev1 img{
  width: 50px;
}
.TVGuideHeadContainer  .TVGuideHeaderTitle {
    font-size: 16px !important;
}
.dateEnd h4 {
  font-size: 13px;
}
.previousDate h4 {
  font-size: 13px;
}
}
@media (max-width: 1200px) {
  .todaysGoal h3 {
    font-size: 13px;
}
  .arrowNext img{
    width: 52px;
}
.arrowPrev img{
  width: 52px;
}
  .WhatDate {
    height: 50px;
}
.arrowNext {
  top: 18px;
}
.TVGuideHeadContainer .arrowNext1 {
  top: 18px;
}
.arrowPrev {
  top: 18px;
}
.TVGuideHeadContainer .arrowPrev1 {
  top: 18px;
}
}
@media (max-width: 1140px) {
  .todaysGoal h3 {
    font-size: 11px;
}
  /* .TVGuideChannelImage {
    height: 120px;
} */
.arrowNext img{
  width: 47px;
}
.arrowPrev img{
width: 47px;
}
  .previousDate h4 {
    font-size: 11px;
  }
  .dateEnd h4 {
    font-size: 11px;
  }
  .TVGuideHeadContainer  .TVGuideHeaderTitle {
    font-size: 13px !important;
}
}
@media (max-width: 1023px) {
  .dateEnd h4 {
    font-size: 14px;
  }
  .previousDate h4 {
    font-size: 14px;
}
  /* .arrowNext {
  } */
  .arrowPrev {
    left: 27% !important;
  }
  .arrowNext img{
    width: 60px;
  }
  .arrowPrev img{
    width: 60px;
  }
  .TVGuideHeadContainer .arrowPrev1 {
   left: 31%;
  }
  .TVGuideHeadContainer .arrowNext1{
    right: 31%;
}
  .TVGuideHeadContainer .arrowPrev{
    display: none;
  }
  .TVGuideHeadContainer .arrowNext{
    display: none;
}
  .TVGuideEventItemTitle h6{
    font-size: 12px;
}
  .TVGuideEventItemTitle h4{
    font-size: 12px;
}
  .TVGuideEventItemTitle p{
    font-size: 12px;
}
   .TVGuideHeaderTitle {
    font-size: 16px !important;
}
.TVGuideHeadContainer  .TVGuideHeaderTitle {
    font-size: 16px !important;
}
}
@media (max-width: 767px) {
  .TVGuideHeadContainer  .TVGuideHeaderTitle {
    padding-bottom: 2px;
}
  .TVGuideHeadContainer .TVGuideHeaderTitle{
    font-size: 14px !important;
}
   .TVGuideHeaderTitle{
    font-size: 14px !important;
}
  /* .TVGuideChannelImage {
    height: 110px;
} */
.TVGuideHeadContainer .arrowNext1{
 right: 29%;
}
.TVGuideHeadContainer .arrowPrev1{
left: 29%;
}
}
@media (max-width: 580px) {
  .TVGuideHeadContainer .TVGuideHeaderTitle {
    font-size: 10px !important;
}
    .TVGuideHeaderTitle {
    font-size: 10px !important;
}
  /* .TVGuideChannelImage {
    height: 140px;
} */
  .dateEnd h4 {
    font-size: 12px;
  }
  .previousDate h4 {
    font-size: 12px;
}
  .arrowNext img{
    width: 40px;
}
  .TVGuideHeadContainer .arrowNext1 img{
    width: 40px;
}
.arrowPrev img{
  width: 40px;
}
.TVGuideHeadContainer .arrowPrev1 img{
  width: 40px;
}
}

@media (max-width: 480px) {
  .TVGuideHeadContainer .TVGuideHeaderTitle {
    font-size: 8px !important;
  }
   .TVGuideHeaderTitle {
    font-size: 10px !important;
  }
  .arrowPrev{
    top: 19px;
  }
  .TVGuideHeadContainer .arrowPrev1{
    top: 19px;
  }
  .arrowNext {
    top: 19px;
}
  .TVGuideHeadContainer .arrowNext1 {
    top: 19px;
}
  .previousDate h4 {
    font-size: 11px;
  }
  .dateEnd h4 {
    font-size: 11px;
  }
  .previousDate h4 {
    font-size: 10px;
  }
  .dateEnd h4 {
    font-size: 10px;
  }
}
@media (max-width: 400px) {
  .TVGuideHeadContainer .arrowNext1 {
    right: 26%;
}
  .TVGuideHeadContainer::after {
    display: none;
}
  .TVGuideHeadContainer::before {
    display: none;
}
  .TVGuideHeadContainer {
    width: 100%;
}
  /* .TVGuideChannelImage {
    height: 100px;
} */
  .arrowNext img{
    width: 30px;
}
  .TVGuideHeadContainer .arrowNext1 img{
    width: 30px;
}
.arrowPrev img{
  width: 30px;
}
.TVGuideHeadContainer .arrowPrev1 img{
  width: 30px;
}
.previousDate h4 {
  font-size: 9px;
}
.dateEnd h4 {
  font-size: 9px;
}
}
@media (max-width: 359px) {
  .previousDate h4 {
    font-size: 9px;
  }
  .dateEnd h4 {
    font-size: 9px;
  }
  .todaysGoal h3{
    font-size: 9px;
  }
  .arrowNext img{
    width: 30px;
}
.arrowPrev img{
  width: 30px;
}
.TVGuideHeadContainer .arrowPrev1 img{
  width: 30px;
}

}
@media (max-width: 330px) {
  .dateEnd h4 {
    font-size: 9px;
  }
  .arrowNext img{
    width: 28px;
}
  .TVGuideHeadContainer .arrowNext1 img{
    width: 28px;
}
  .arrowNext {
   right: 26%;
}
  .arrowPrev img{
    width: 28px;
}
  .arrowPrev {
   left: 27%;
}
.TVGuideHeadContainer .arrowPrev1 img{
  width: 28px;
}

}
