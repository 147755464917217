    
.LiveChannelsContainer .categoryItem {
    height: 280px !important;
}

.LiveChannelsContainer img {
    aspect-ratio: 16/10;
    width: 100%;
    object-fit: cover;
    object-position: left;
}

.LiveChannelsContainer span {
    width: 92%;
    margin: 0px auto 0;
    height: 1px;
    background: white;
    display: block;
    opacity: 0.8;
}

.liveSlider {
    background-color: #000;
    z-index: 1;
    box-shadow: 5px -10px 49px 20px rgb(0 0 0);
    position: relative;
}

.LiveChannelsContainer .react-multiple-carousel__arrow {
    background-color: blue;
}
/* @media (max-width: 1920px) {
    .LiveChannelsContainer img {
        height: 215px;
    }
}
@media (max-width: 1700px) {
    .LiveChannelsContainer img {
        height: 200px;
    }
}

@media (max-width: 1400px) {
    .LiveChannelsContainer img {
        height: 200px;
    }
}

@media (max-width: 1200px) {
    .LiveChannelsContainer img {
        height: 180px;
    }
}

@media (max-width: 1023px) {
    .LiveChannelsContainer img {
        height: 140px;
    }
}
@media (max-width: 949px) {
    .LiveChannelsContainer img {
        height: 155px;
    }
}
@media (max-width: 767px) {
    .LiveChannelsContainer img {
        height: 112px;
    }
} */

@media (max-width: 767px) {
    .categoryItem {
        margin: 0.5rem 3px;
    }
}

/* @media (max-width: 400px) {
    .LiveChannelsContainer img {
        height: 88px;
    }
} */
