.tvShowAll {
  width: 92%;
  margin: 0 auto;
  display: grid;
  grid-gap: 12px;
  gap: 13px;
  grid-template-columns: repeat(6, 1fr);
}

.loaderContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
}
@media (max-width: 1700px) {
  .tvShowAll img {
    height: 280px;
}
}
@media (max-width: 1600px) {
  .tvShowAll img {
    height: 260px;
}
}
@media (max-width: 1500px) {
  .tvShowAll img {
    height: 250px;
}
}
@media (max-width: 1400px) {
  .tvShowAll {
    gap: 10px;
    grid-template-columns: repeat(5, 1fr);
}
.tvShowAll img {
  height: 270px;
}
}
@media (max-width: 1300px) {
  .tvShowAll img {
    height: 260px;
}
}
@media (max-width: 1240px) {
  .tvShowAll {
    grid-template-columns: repeat(4, 1fr);
}
  .tvShowAll img {
    height: 300px;
}
}
@media (max-width: 1100px) {
  .tvShowAll img {
    height: 260px;
}
}
@media (max-width: 950px) {
  .tvShowAll img {
    height: 240px;
}
}
@media (max-width: 900px) {
  .tvShowAll {
    grid-template-columns: repeat(3, 1fr);
}
.tvShowAll img {
  height: 280px;
}
}
@media (max-width: 800px) {
  .tvShowAll img {
    height: 260px;
  }
  }
  @media (max-width: 750px) {
    .tvShowAll img {
      height: 220px;
    }
  
    .tvShowAll {
      width: 95%;
    }
  }
  @media (max-width: 640px) {
   
    .tvShowAll {
      grid-template-columns: repeat(2, 1fr);
  }
  .tvShowAll img {
    height: 300px;
  }
  }
  @media (max-width: 550px) {
    .tvShowAll img {
      height: 270px;
    }
  }
  @media (max-width: 480px) {
    .tvShowAll img {
      height: 220px;
    }
  }
  @media (max-width: 420px) {
    .series-seasons-container>div>button:last-child:before {
     display: none;
  }
    .series-seasons-numbers {
      background: #2e3192;
  }
    .tvShowAll img {
      height: 210px;
    }
  }
  @media (max-width: 380px) {
    .tvShowAll img {
      height: 190px;
    }
    .tvShowAll>div>div>div:first-child{
      height: 180px;
    }
  }
  