.Footer {
  display: flex;
  color: var(--white);
  width: 92%;
  margin: 0 4% 0 4%;
  line-height: 20px;
  padding: 1.5em 0 2em;
  border-top: 1px solid #fff;

}

.FooterSection {
  background: #000;
  margin-top: 40px;
}

.Footer_Description_Heading,
.Footer_Menu_Heading,
.Footer_SocialMedia_Heading {
  height: 52px;
  letter-spacing: 0.5px;
  color: var(--white);
  font-weight: 500;
}

.Footer_Menu_Heading,
.Footer_SocialMedia_Heading {
  display: flex;
  align-items: center;
}

.Footer_Menu a,
.Footer_SocialMedia a {
  color: var(--silver);
  text-decoration: none;
}

.Footer_Menu a:hover,
.Footer_SocialMedia a:hover {
  color: var(--brandHover);
  text-decoration: none;
  transition: 0.1s ease;
}

.Footer_Menu,
.Footer_SocialMedia {
  flex: 1;
}

.Footer_SocialMedia_Heading_Info,
.Footer_Menu_info {
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
  color: var(--silver);
  /* gap: 10px; */
  align-items: flex-start;
}

.Footer_SocialMedia_Heading_Info>*+* {
  margin-top: 10px !important;
}

.Footer_Menu_info>*+* {
  margin-top: 10px !important;
}

.Footer_Description {
  color: var(--blueSilver);
  text-align: center;
  margin-bottom: 30px;

  /* flex: 3; */
}

.Footer_Description_Heading_Info {
  margin-top: 50px;
  text-align: center;
  color: #dadada;
}

.Footer_SocialMedia_Links {
  display: flex;
  /* gap: 20px; */
  justify-content: center;
  align-items: center;
}

.Footer_SocialMedia_Links>*+* {
  margin-left: 10px;
}

.Footer_SocialMedia_Links_icons {
  color: var(--blueSilver);
}

.Footer_bottom_link ul {
  /* padding: 7em 0; */
  list-style-type: none;
  margin-left: 0;
  display: flex;
  align-items: center;
}

.Footer_bottom_link {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Footer_bottom_link ul li a {
  color: #fff;
  margin: 0 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
}

.Footer_Box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.Footer_Box .SocialContents {
  display: flex;

  align-items: center;
}

.Footer_Box .SocialContents .StoreContent {
  display: flex;
  align-items: center;
}

.Footer_Box .PlayStore {
  height: 55px;
}

.Footer_Box .AppStore {
  height: 35px;
}

/* .Footer_social_link{
  margin-top: 30px;
} */
.Footer_social_link {
  margin-right: 20px;
}

.Footer_social_link ul {
  list-style-type: none;
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Footer_social_link ul li a {
  margin: 0 10px;
  color: #fff;
  text-decoration: none;
}

.Footer_social_link ul .FooterContact {
  margin-bottom: 4px;
}

.Footer_social_link ul li a svg {
  width: 24px;
  height: 24px;
  color: #fff !important;
}

.Footer_social_link ul li a:hover svg path {
  fill: #fff;
}
.navColorBar {
  display: flex;
}

.navColorBar1 {
  height: 12px;
  background-color: #2e3192;
  width: 68%;
}

.navColorBar2 {
  height: 12px;
  background-color: #f7941e;
  width: 32%;
  position: relative;
}
.navColorBar2::after {
  position: absolute;
  content: "";
  height: 12px;
  background-color: #f7941e;
  width: 20px;
  left: -9px;
  bottom: 0;
      transform: skewX(-40deg);
}


/* media Queeries */
@media (max-width: 1440px) {
  .SocialContents ul li a {
    font-size: 12px;
    margin: 0 7px;
}
  .SocialContents ul li a svg{
    height: 18px;
}

.Footer_social_link {
  margin-right: 7px;
}
  .Footer_bottom_link ul li a {
    font-size: 12px;
    margin: 0 7px;
}
 .Footer_Box .PlayStore{
    height: 45px;
}
.Footer_Box .AppStore{
    height: 31px;
}
}

@media (max-width: 1200px) {
  .SocialContents ul li a {
    font-size: 11px;
    margin: 0 2px;
}
  .SocialContents ul li a svg{
    height: 15px;
}

.Footer_social_link {
  margin-right: 0px;
}
  .Footer_bottom_link ul li a {
    font-size: 11px;
    margin: 0 5px;
}
 .Footer_Box .PlayStore{
    height: 40px;
}
.Footer_Box .AppStore{
    height: 26px;
}
}

@media (max-width: 1023px) {
  .Footer_Box .SocialContents {
    margin-top: 20px;
}
  .Footer_Box{
    flex-direction: column;
    align-items: center;
}
  .Footer_bottom_link ul {
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
  }
}

@media (max-width: 767px) {
  .FooterSection{
    margin-bottom: 50px;
  }
  /* .FooterSection{
    display: none;
  } */
  .Footer_Box .SocialContents {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: center;
  }

  .Footer {
    padding: 3em 0 2.5em;
  }
}

@media (max-width: 600px) {
  .FooterSection{
    margin-bottom: 38px;
  }

  .Footer_Description_Heading_Info {
    padding: 10px 10px 10px 0;
  }

  .Footer_SocialMedia_Heading_Info,
  .Footer_Menu_info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    /* gap: 0px; */
  }

  .Footer_SocialMedia_Heading_Info>*+* {
    margin-top: 0px !important;
    margin-left: 10px !important;
  }

  .Footer_Menu_info>*+* {
    margin-top: 0px !important;
    margin-left: 10px !important;
  }
}