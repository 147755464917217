.PosterContent {
    width: 92%;
    margin: 45px auto 30px;
    position: relative;
    cursor: pointer;
}
.PosterContent img {
    width: 100%;

    height: 100%;
    object-fit: cover;
}

@media (max-width: 580px) {
    .PosterContent {
        margin: 0 auto;
    }
}
