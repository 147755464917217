.categoryMain {
    padding: 30px 0;
}

.categoryMainWrap {
    padding: 30px 6%;
}

.ListAllCategories {
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(4, 1fr);

}

.ListAllCategories>div {
    background-color: #000;
    border-radius: 10px;
}

.categorySelect {
    box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 48%);
    -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 48%);
    -moz-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 48%);
    background: #262628;
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #323232;
    outline: none;
    position: relative;
}

.selectBox {
    border-left: 2px solid #febd0e;
    padding-left: 10px;
}
.categoriesContainermain .ViewedSectionSecondary__3videos__image {
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  height: 1000px;
}
.categoryItems>div:nth-child(1){
    height: 500px ;
  }
.categoryItems img{
    height: 100%;
  }
@media (max-width: 1800px) {
   .categoryItems>div:nth-child(1){
    height: 450px;
  }
  }
@media (max-width: 1600px) {
   .categoryItems>div:nth-child(1){
    height: 400px;
  }
  }
  @media(max-width: 1500px) {
  }
    @media (max-width: 1400px) {
       .categoryItems>div:nth-child(1){
        height: 350px;
      }
       .categoryItems>div:nth-child(1) img{
        height: 100%;
      }
      }
    @media (max-width: 1200px) {
       .categoryItems>div:nth-child(1){
        height: 300px;
      }
      }
    @media (max-width: 1100px) {
       .categoryItems>div:nth-child(1){
        height: 260px;
      }
      }

@media(max-width: 1023px) {
    .categoryItems>div:nth-child(1){
        height: 300px;
      }
    .ListAllCategories {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media(max-width: 850px) {
    .categoryItems>div:nth-child(1){
        height: 270px;
      }
}
@media(max-width: 650px) {
    .categoryItems>div:nth-child(1){
        height: 220px;
      }
}

@media(max-width: 580px) {
    .categoryItems>div:nth-child(1){
        height: 240px;
      }
    .ListAllCategories {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media(max-width: 440px) {
    .ListAllCategories {
        grid-template-columns: repeat(1, 1fr);
    }
    .categoryItems>div:nth-child(1){
        height: 380px;
      }
}